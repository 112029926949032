import {
  Autocomplete,
  Button,
  TextField,
  Modal,
  Card,
  Stack,
  Typography,
  Avatar,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";

function App() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedPerson, setSelecedPerson] = useState(null);
  const [listOfEmployees, setListOfEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const initFetchInterval = () =>
    setInterval(() => fetchEmployees(), 1000 * 60 * 60); // 1 hour

  useEffect(() => {
    fetchEmployees();
    initFetchInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmployees = () => {
    resetAll();
    const token =
      "DQVJ2VTRwNVZAsbXRXTG9jeFRRajBGQV9Fbm1CT0pTbUUtNV9uLWhfaTM3UFVEWlRWNVZAPUnVzcHQxVEt6NmRqVUdJY2Y3TUlaRF9TZAjJzV3RhSmo4SkcxMVBoeGxSVlJicFhPUTBURURnWkM5QzUwRFNfM0pUS09xekVxVHliWEluT3JhM0twa3g0WFB4VWlKcWlUTFc5NHJGTG1hYTlKdjFNdHhCQTdpX3VkbDR1d1E5NS1ZAUWV6a2RhMFhaaElSd3RmVld1bERORk9FVXlWVgZDZD";
    const fields = "department,name,phones,active,first_name";

    axios
      .get(
        `https://graph.facebook.com/community/members?fields=${fields}&access_token=${token}&limit=200`
      )
      .then(({ data }) => {
        const employees = data.data
          .filter((emp) => emp.phones && emp.name && emp.active)
          .map((emp) => ({
            ...emp,
            phonenumber: emp.phones.data[0].number.replaceAll("-", ""),
            label: `${emp.name}${
              emp.department ? " (" + emp.department + ")" : ""
            }`,
          }));

        employees.length && setListOfEmployees(employees);
      })
      .catch(() => setErrorMessage("Kunne ikke hente ansatte."));
  };

  const sendNotification = () => {
    axios
      .post(
        "https://admin-extra.fjellsport.no/smsgw/send",
        {
          source: "Fjellsport",
          destination: selectedPerson?.phonenumber,
          userData: `Hei, ${selectedPerson?.first_name}. Du har en besøkende som venter på deg ved inngangen til K1.`,
        },
        {
          auth: {
            username: "586463042616305",
          },
        }
      )
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch(() => setErrorMessage("Kunne ikke sende melding."));
  };

  const resetAll = () => {
    setShowSuccessModal(false);
    setSelecedPerson(null);
    document.activeElement.blur();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const initTimeout = () => setTimeout(() => resetAll(), 60000); // 1 minut

  const handleInputChange = (_, newValue) => {
    setSelecedPerson(newValue);
    initTimeout();
  };

  const getSortedEmployeeList = () => {
    return listOfEmployees.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <div className="App">
      <div className="App__topinfo">
        Ser du etter <strong>hentepunktet?</strong> Det finner du på andre siden
        av bygget.
      </div>
      <div className="App__main">
        <div className="App__main_content">
          <h1 style={{ fontWeight: 700, fontSize: "2.5em" }}>
            Hei 👋 Hvem skal du møte i dag?
          </h1>
          <Autocomplete
            disablePortal
            value={selectedPerson}
            isOptionEqualToValue={(option, value) =>
              JSON.stringify(option) === JSON.stringify(value)
            }
            onChange={handleInputChange}
            id="employee-chooser"
            options={getSortedEmployeeList()}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ background: "#fff", marginBottom: "24px" }}
                label="Velg person"
              />
            )}
          />
          <Button
            variant="contained"
            disabled={!Boolean(selectedPerson)}
            onClick={sendNotification}
          >
            Gi beskjed til vedkommende
          </Button>
        </div>
      </div>
      <div className="App__footer">
        <Button onClick={() => window.location.reload()}>
          <img
            src="Fjellsport-LOGO.png"
            width="200"
            alt="Fjellsport logo"
          ></img>
        </Button>
      </div>
      <Modal
        open={showSuccessModal}
        onClose={resetAll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="The office gif"
              src="theoffice.gif"
              sx={{ width: 100, height: 100 }}
            />
            <div>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                {`${selectedPerson?.name} har blitt informert.`}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Vennligst vent her til vedkommende kommer og henter deg.
              </Typography>
            </div>
          </Stack>
        </Card>
      </Modal>
      <Snackbar open={Boolean(errorMessage)}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage + " Trykk "}
          <Link href={"."}>her</Link>
          {" for å laste inn siden på nytt."}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
